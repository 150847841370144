//TODO:
// - EP link?
/*
<div className='row landing-btns'>
        <div className='col'>
          <Link to='/eatonporter' className='my-link'>
            <div className='landing-btn'>
              <div className='landing-btn-text'>
                <p>Eaton Porter</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
*/

import React, { useEffect } from "react"
import { Link } from "react-router-dom"

const Landing = () => {
  useEffect(() => {
    document.title = "Cameron Magari"
  })

  return (
    <div className='container landing main'>
      <div className='row landing-btns lbp'>
        <div className='col'>
          <Link to='/projects' className='my-link '>
            <div className='landing-btn'>
              <div className='landing-btn-text'>
                <p>Experience</p>
              </div>
            </div>
          </Link>
        </div>
        <div className='col'>
          <Link to='/about' className='my-link'>
            <div className='landing-btn'>
              <div className='landing-btn-text'>
                <p>About</p>
              </div>
            </div>
          </Link>
        </div>
        <div className='col'>
          <Link to='/contact' className='my-link'>
            <div className='landing-btn'>
              <div className='landing-btn-text'>
                <p>Contact</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Landing
