import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import portrait from "../../assets/files/portrait.jpg"

import certs from "../../assets/projects/certs"

let cert = []

export const About = () => {
  const [Data, setData] = useState({
    targetData: null,
    focus: false,
    proj: certs,
    certsLoaded: false,
  })

  const { targetData, focus, proj, cardsLoaded } = Data

  useEffect(() => {
    cert = []
    certs.forEach((el, idx) => {
      let cert
    })
    setData({ ...Data, certsLoaded: true })
  }, [])

  return (
    <div className='main'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 col-md-6'>
            <img src={portrait} className='portrait' />
          </div>
          <div className='col-lg-7 col-md-6  pl-sm-5'>
            <h3>Hi, I’m Cameron Magari.</h3>
            <h5>
              My passion for engineering was fostered early, as I spent much of
              my childhood helping out my father in our workshop. In light of
              this, I prefer a hands-on approach to my endeavors and am never
              afraid to get my hands dirty - in fact, it's an inevitability!
            </h5>
            <h5>
              I graduated, in spring 2022, with a degree in Mechanical
              Engineering and a concentration in automation and robotics. I
              encourage you to explore this site to learn more about my
              experience and expertise.
            </h5>
            <h5>
              When I’m not working on personal projects, I love to spend time
              outdoors hiking and camping with friends.
            </h5>
            <div className='mt-3'>
              <a
                className='btn btn-dark'
                href='https://drive.google.com/file/d/10Hydcp7doAVTGmHT0EpNLLshMxiyemjE/view?usp=sharing'
                target='_blank'
                role='button'
                rel="noopener noreferrer"
              >
                RESUME
              </a>
            </div>
          </div>
        </div>
        <div className='row mt-3' style={{ borderTop: "black 1px" }}>
          {false && <h3>Certifications</h3>}
        </div>
      </div>
    </div>
  )
}

export default About
