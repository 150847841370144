// TODO:
/*
<div className='col-4 footerCol'>
              <ul className='footer-list'>
                <li onClick={(e) => scrollTop(e)}>
                  <Link className='footer-list-link' to='/eatonporter'>
                    Eaton Porter
                  </Link>
                </li>
              </ul>
            </div>
*/

import React, { Fragment, useEffect } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import { ReactComponent as Thing } from "../../assets/icons/thingiverse.svg"
import { ReactComponent as Linkedin } from "../../assets/icons/linkedin.svg"
import { ReactComponent as Thangs } from "../../assets/icons/thangs.svg"

export const Footer = () => {
  const scrollTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <footer className='footer'>
      <div>
        <div className='container'>
          <div className='row'>
            <div className='col-4 footerCol'>
              <p>Connect</p>
              <a
                href='https://www.linkedin.com/in/cameron-magari'
                target='_blank'
                rel="noopener noreferrer"
              >
                <Linkedin className='footerIcon' />
              </a>
              <a 
              href='https://thangs.com/designer/cmagari' 
              target='_blank'
              rel="noopener noreferrer"
              >
                <Thangs className='footerIcon' />
              </a>
              <a
                href='https://www.thingiverse.com/cmagari/designs'
                target='_blank'
                rel="noopener noreferrer"
              >
                <Thing className='footerIcon' />
              </a>
            </div>
            <div className='col-4 footerCol'>
              <ul className='footer-list'>
                <li onClick={(e) => scrollTop(e)}>
                  <Link className='footer-list-link' to='/projects'>
                    Experience
                  </Link>
                </li>
                <li onClick={(e) => scrollTop(e)}>
                  <Link className='footer-list-link' to='/about'>
                    About
                  </Link>
                </li>
                <li onClick={(e) => scrollTop(e)}>
                  <Link className='footer-list-link' to='/contact'>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='footerEnd'>
        <div className='container'>
          <div className='row'>
            <p className='mx-auto mt-1'>website designed & built by me</p>
            <p className='mx-auto'>&#169; Cameron Magari 2024</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
