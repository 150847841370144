const data = [
  {
    title: "Silverside Detectors",
    date: "2022-2023",
    desc: "From summer 2022 to fall 2023, I was employmed as a mechanical engineer at Silverside Detectors, a small company based in Boston, MA. I worked on a variety of DARPA and NSF funded projects, ranging from R&D work on novel CRNS systems for ground water detection to manufacturing improvements and their next generations of neutron detectors.",
    status: "Complete",
    img: "SSLogo.png",
    writeup: "/sside",
    links: [""],
    tags: "Mechanical Engineering, R&D, Mechatronics, Manufacturing",
  },
  {
    title: "LibreKraft",
    date: "2023-Present",
    desc: "Toward the end of 2023, I decided to do a better job documenting and sharing the projects I was working on- mainly through various social media platforms such as YouTube. Hopefully, it will serve as a good archive for me and an inspiration for others.",
    status: "Ongoing",
    img: "LKLogo.png",
    writeup: "/librekraft",
    links: [""],
    tags: "",
  },
  {
    title: "Automated Dog Feeder",
    date: "Fall 2022",
    desc: "At the request of my dad, I made an RFID enabled set of dog feeders for my two childhood dogs. It enables the dogs to get feed if my parents are out of town for a night.",
    status: "Complete",
    img: "DOG.jpg",
    writeup: "/dogfeeder",
    links: [""],
    tags: "Mechanical Engineering, Mechatronics, Manufacturing, Dogs",
  },
  {
    title: "ME Capstone",
    date: "Spring 2022",
    desc: "For ME Capstone, my team and I created a functional prototype of an automated spice dispensing machine. I led the mechanical design, fabrication, and component selection for the project.",
    status: "Complete",
    img: "CAP.jpg",
    writeup: "/projects/capstone",
    links: [""],
    tags: "Machine Design, Mechatronics, Arduino, Electronics, 3D Printing",
  },
  {
    title: "Low Mobility Headbrace",
    date: "Fall 2021",
    desc: "For ME 4405, a classmate and I designed and prototyped a 2 degree of freedom headbrace for patients with low neck and head mobility, such as someone suffering from late stage ALS. The powered device travels to and maintains a desired angular position.",
    status: "Complete",
    img: "ME4405.jpg",
    writeup:
      "https://drive.google.com/file/d/1oAeIw1n0S3klNhdsr2IeZ6moJUWURdr9/view?usp=sharing",
    links: [""],
    tags: "MSP432, C, Electronics, 3D Printing",
  },
  {
    title: "Wake Winch",
    date: "Summer 2021",
    desc: "During the summer of 2021 I designed and built my own 15HP wake winch for towing tubes, wakeboards, etc. on small bodies of water.",
    status: "Complete",
    img: "WAKE.jpg",
    writeup:
      "https://drive.google.com/file/d/1JIbRqwKpGf1mNzoHZ0dmLYsxgKcgezNe/view?usp=sharing",
    links: [""],
    tags: "Machine Design, Fabrication",
  },
  {
    title: "Creare Covid Monitor",
    date: "Summer 2020",
    desc: "During the summer of 2020 I designed, prototyped, and iterated on a platform for monitoring pertinent COVID-19 information at a small business. This eventually evolved into a fully functioning web application coupled with a dozen on-site consoles that were used daily by any of some 200 employees that happened to not work remotely.",
    status: "Complete",
    img: "CAM.jpg",
    writeup:
      "https://drive.google.com/file/d/1hthBfhQ-ns4GhON1JHemcIaK1lF4Y_Gf/view?usp=sharing",
    links: [""],
    tags: "Python, ReactJS, MongoDB, Raspberry Pi, Electronics",
  },
  {
    title: "I2P Hydroponics",
    date: "Fall 2019 - Spring 2020",
    desc: "Throughout sophomore year of college I worked on a school funded project to design and prototype affordable, efficient consumer hydroponic devices.",
    status: "Complete",
    img: "I2P.jpg",
    writeup:
      "https://drive.google.com/file/d/1_Uvrtz8XvO8YVWDG2jou3OHRpEephYeN/view?usp=sharing",
    links: [""],
    tags: "Python, Arduino, Firebase, CAD, 3D Printing",
  },
]

export default data
