import React, { Fragment, useEffect } from "react"
import { Link } from "react-router-dom"

export const Capstone = () => {
  return (
    <div className='main'>
      <div className='container'>
        <div className='row'>
          <h2>Undergrad ME Capstone</h2>
        </div>
        <div className='row'>
          <p>
            For my undergraduate capstone project my group conceived of,
            designed, and created a functional prototype of an IoT spice
            dispenser. A recording of our final presentation is available below,
            and covers our motivations, design process, and results.
            Additionally, a video we played at the expo is provided and shows
            some of the operation of the device.
          </p>
          <p>
            Most of my responsibilities on the project pertained to the third
            and final design iteration, for which I was primarily responsible. I
            designed the cartridges and the compliant mechanism for attaching
            and detaching them readily. I modeled and created the assemblies for
            rotating the upper stage of the design and engaging individual
            cartridges. In addition to being responsible for much of the
            aesthetic design of the product, I also manufactured most of the
            parts.
          </p>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <h4 style={{ textAlign: "center" }}>Capstone Presentation</h4>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            <iframe
              style={{ width: "100%", height: "40vh" }}
              src='https://www.youtube.com/embed/s6-fKpImVvY'
              title="Capstone Presentation"
            ></iframe>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <h4 style={{ textAlign: "center" }}>Expo Video</h4>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            <iframe
              style={{ width: "100%", height: "40vh" }}
              src='https://www.youtube.com/embed/SgHcKzjd8g0'
              title="Capstone Expo Video"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Capstone
