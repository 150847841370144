import React, { Fragment, useEffect } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

export const Navbar = () => {
  return (
    <nav className='navbar navbar-expand-sm navbar-light bg-light'>
      <div className='container nav-container'>
        <Link className='navbar-brand' to='/'>
          Cameron Magari
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav ml-auto'>
            <li className='nav-item'>
              <Link className='nav-link' to='/projects'>
                Experience
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/about'>
                About
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/contact'>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
