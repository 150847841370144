import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Project from "../projects/Project";
import ProjCard from "../projects/ProjCard";

import data from "../../assets/projects/data";

let cards = [];

export const Projects = () => {
  const [Data, setData] = useState({
    targetData: null,
    focus: false,
    proj: data[0],
    cardsLoaded: false,
  });

  const { targetData, focus, proj, cardsLoaded } = Data;

  useEffect(() => {
    cards = [];
    data.forEach((el, idx) => {
      let card = <ProjCard info={el} key={idx} />;
      cards.push(card);
    });
    setData({ ...Data, cardsLoaded: true });
  }, []);

  return (
    <div className="main">
      <div className="container">
        <div id="projView" style={{ display: focus ? "none" : "block" }}>
          {cardsLoaded && cards}
        </div>
      </div>
    </div>
  );
};

export default Projects;
