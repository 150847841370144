import React, { useEffect, useState } from "react";

export const DogFeeder = () => {
  return (
    <div className="main">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <h4 style={{ textAlign: "center" }}>Demo</h4>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            <iframe
              style={{ width: "100%", height: "40vh" }}
              src="https://www.youtube.com/embed/eUfwwtODY6Y?si=hgbR9R5JMW3WEcPE"
              title="demo"
            ></iframe>
          </div>
        </div>
        <div className="row mt-5">
          <p>
            See the video above for a video demonstration and a basic wiring
            diagram.
          </p>
          <p>
            The operation of the system is not particularly complicated. I built
            a housing and added electronics that “wrap” an existing wifi dog
            feeder. Each dog feeder can hold about a week’s worth of food and
            can be scheduled from an app. At the appropriate breakfast and
            dinner time it dispenses food into a hopper. This is where what I
            made starts.
          </p>
          <p>
            An ESP8266 connected to wifi monitors the time and plays a beeping
            noise when there is a meal available (meals and other pertinent
            information are stored in EEPROM in case of power outages). There is
            a Wiegand RFID sensor on the front that reads small HID tags on the
            dogs’ collars. When the correct dog is present, and there is a meal
            available, the ESP8266 opens a blast gate, releasing the food from
            the hopper. There is also a small screen on each with a menu that
            displays the dog’s name, ID number, and lets you control a training
            mode - though my two dogs are food motivated enough that little
            training was actually required.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DogFeeder;
