// TODO:
// - add link icons and links

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SSLogo from "../thumbnails/SSLogo.png";

import CRNS1 from "../../../assets/files/CRNS1.jpg";
import CRNS2 from "../../../assets/files/CRNS2.jpg";

export const Sside = () => {
  return (
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="col-3">
            <img className="EPL" src={SSLogo} alt="EP logo" />
          </div>
          <div className="col">
            <h2>Overview</h2>
            <p>
              In the summer of 2022, I started my first job, post-graduation, as
              a mechanical engineer at a small company called Silverside
              Detectors, based outside Boston, MA. While the funding issues of
              being a small company eventually led to a shorter tenure than I
              had hoped, I was able to learn so, so much in my time there. I got
              the opportunity to participate in both R&D and manufacturing work
              across a range of projects. Additionally, I got exposure to
              important non-technical aspects of engineering such as grant and
              report writing, project management, and setting up PLM systems.
            </p>
            <p>
              Below I highlight some of the projects I worked on and elaborate
              beyond the limits of a short resume.
            </p>
          </div>
        </div>
        <div className="row">
          <h2>CRNS Research</h2>
          <p>
            Much of my time at Silverside was spent, in various capacities,
            working on an NSF backed project exploring the applications of
            Cosmic Ray Neutron Sensing (CRNS) for soil hydrology. In a sentence,
            neutron signals are inversely correlated with soil water content and
            therefore can give a spatial average rather than point measurements
            achieved by existing techniques, such as electrical conductivity
            (EC) sensors.
          </p>
          <p>
            If you are more curious about the science behind this and its
            intricacies{" "}
            <a
              href="https://essd.copernicus.org/articles/15/3243/2023/"
              target="_blank"
              rel="noopener noreferrer"
            >
              this article
            </a>{" "}
            is a decent starting place.
          </p>
          <p>
            The goal of the project was to determine the efficacy of a tightly
            spaced grid of lower sensitivity sensors; existing scientific
            literature has recently begun to explore large scale (hundreds and
            thousands of meter spacing) networks of CRNS sensors. We conducted a
            months-long experiment on the driving range of Paradise Valley Golf
            Course outside Phoenix, AZ- who SilverSide has collaborated with on
            previous CRNS projects prior to me joining the company.
          </p>
          <p>
            I worked on the experimental setup which consisted of 30 valve boxes
            buried in a 20 meter grid on the driving range. I designed and
            specified the necessary electrical components, wire, and built a
            base station through which all the power was routed. In addition, I
            was integral to the development of hardware placed in each valve
            box. I worked on much of the software that recorded data,
            integrating a single board computer with 3rd party neutron sensors
            (and later our in house built detectors- see Chamber Miniaturization
            section).
          </p>
          <p>
            It became apparent very quickly with the first prototypes in the
            field that the interior of the valve boxes experienced large
            temperature swings, high humidity, and occasional flooding. As such,
            I also assisted in efforts in testing and validation of enclosures
            and hardware for field use.{" "}
          </p>
          <p>
            After the raw neutron signals had been cleaned and converted to
            rough soil moisture estimates, I performed secondary analysis on the
            results. I generated heatmaps using a variety of interpolations and
            also created a variety of other visuals.
          </p>
          <p>
            To read the final report of the NSF Phase I SBIR click{" "}
            <a
              href="https://drive.google.com/file/d/1xPsmYygcEuB5rBQgWfr46y4V0qQP-ADe/view?usp=drive_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </p>
          <p>
            To see some of the other data visualizations I generated click{" "}
            <a
              href="https://www.youtube.com/playlist?list=PLjWRdQbxFY9J5JImxYslsEt_La2-u7V57"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </p>
        </div>
        <div className="row">
          <div className="col">
            <img className="sside-crns-pic p-1" src={CRNS2} alt="EP logo" />
          </div>
          <div className="col">
            <img className="sside-crns-pic p-1" src={CRNS1} alt="EP logo" />
          </div>
        </div>
        <div className="row">
          <h2 className="hidden">Improved Chamber Design</h2>
        </div>
        <div className="row">
          <h2 className="hidden">MISC</h2>
        </div>
      </div>
    </div>
  );
};

export default Sside;
