const certs = [
  {
    org: "MathWorks",
    certs: [
      {
        title: "MATLAB Onramp",
        link: "https://drive.google.com/file/d/1JaVhHIgmb5Grz10Zyr4ttW6wm0yrVlhl/view?usp=sharing",
      },
      {
        title: "MATLAB Simulink Onramp",
        link: "https://drive.google.com/file/d/1xZziuDcGgHwD98NzGk2MY-TQo0YuACts/view?usp=sharing",
      },
      {
        title: "MATLAB Signal Processing Onramp",
        link: "https://drive.google.com/file/d/1AxAsnL94cjMf1c3c2b6GMADhbE9Xgkcb/view?usp=sharing",
      },
      {
        title: "MATLAB Reinforcement Learning Onramp",
        link: "https://drive.google.com/file/d/1CC3rJeyYRdQ-4oLL4lGocyeiP8Sqxq4c/view?usp=sharing",
      },
      {
        title: "MATLAB Machine Learning Onramp",
        link: "https://drive.google.com/file/d/1PP00Z8Lv69QIwIMjDFR17Zn4LEzk_Dwb/view?usp=sharing",
      },
      {
        title: "MATLAB Image Processing Onramp",
        link: "https://drive.google.com/file/d/1MhDGXWkfpXUWLwOXrdyXBjQylqUqQ6HS/view?usp=sharing",
      },
      {
        title: "MATLAB Deep Learning Onramp",
        link: "https://drive.google.com/file/d/1lONiMddfPvRaln9X5uEZZS7HvHf0Au2e/view?usp=sharing",
      },
      {
        title: "MATLAB Fundamentals",
        link: "https://drive.google.com/file/d/1lONiMddfPvRaln9X5uEZZS7HvHf0Au2e/view?usp=sharing",
      },
      {
        title: "Introduction to Symbolic Math with MATLAB",
        link: "https://drive.google.com/file/d/1XvF0IN8tqXe20aQFiuJb8TPqRT6gG6YS/view?usp=sharing",
      },
      {
        title: "Optimization Onramp",
        link: "https://drive.google.com/file/d/1FNWP-hGn6XgB2HF9uYLML688QeTZM6MD/view?usp=sharing",
      },
      { title: "Circuit Simulation Onramp", link: "" },
      { title: "Wireless Communication Onramp", link: "" },
      { title: "Simscape Onramp", link: "" },
      { title: "Stateflow Onramp", link: "" },
      { title: "Control Design Onramp with Simulink", link: "" },
      { title: "MATLAB for Data Processing and Visualization", link: "" },
      { title: "MATLAB Programming Techniques", link: "" },
      { title: "Simulink Fundamentals", link: "" },
      { title: "Machine Learning with MATLAB", link: "" },
      { title: "Deep Learning with MATLAB", link: "" },
      { title: "Solving Nonlinear Equations with MATLAB", link: "" },
      {
        title: "Solving Ordinary Differential Equations with MATLAB",
        link: "",
      },
      { title: "Introduction to Linear Algebra with MATLAB", link: "" },
      { title: "Introduction to Statistical Methods with MATLAB", link: "" },
      { title: "Image Processing with MATLAB", link: "" },
      { title: "Signal Processing with MATLAB", link: "" },
    ],
  },
]

export default certs
