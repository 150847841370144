import React, { Fragment, useEffect } from "react"
import { Link } from "react-router-dom"

export const Contact = () => {
  return (
    <div className='main'>
      <div className='container'>
        <h2 style={{ textAlign: "center", paddingTop: "25vh" }}>
          email inquiries to: <span className='em'>cmagari@gmail.com</span>{" "}
        </h2>
      </div>
    </div>
  )
}

export default Contact
