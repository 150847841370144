// TODO:
// - add link icons and links

import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import LKLogo from "../thumbnails/LKLogo.png"
import STRING_ART from "./lk_thumbnails/string_art.jpg"
import CYCLOID from "./lk_thumbnails/cycloid.jpg"
import TURNTABLE from "./lk_thumbnails/turntable.jpg"

import www from "../../layout/ep/www.png"
import insta from "../../layout/ep/insta.png"
import pat from "../../layout/ep/pat.png"
import tik from "../../layout/ep/tik.png"
import yt from "../../layout/ep/yt.png"

export const LibreKraft = () => {
  return (
    <div className='main'>
      <div className='container'>
        <div className='row'>
          <div className='col-3'>
            <img className='EPL' src={LKLogo} alt="EP logo" />
          </div>
          <div className='col'>
            <h2>About</h2>
            <p>
              At the end of 2023, I decided to find a better way to showcase my
              independent endeavors. In the past, I have worked on a variety of projects, 
              ranging from mechanical engineering to sofware development to woodworking and 
              have failed to document much of it along the way. Not only is this poor
              practice with respect to demonstrating my skills and reflecting on my work, 
              but is also less than ideal in the sense that I believe art, science,
              and engineering are meant to be shared for the benefit of others.
            </p>
            <p>
              I decided to brand it under "LibreKraft" for no particular reason. You 
              can explore my work in the links below. If you enjoy anything you see, 
              you're more than welcome to like, comment, share, or subscribe, etc!
            </p>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col hidden'> {/* website link */}
            <a 
              className='epLink' 
              href='https://www.librekraft.com/' 
              target='_blank'
              rel='noreferrer'
              >
              <div className='epIconText'>Website</div>
              <div className='epIconText footerIcon'>
                <img className='epIcon' src={www} alt="youtube logo" />
              </div>
            </a>
          </div>
          <div className='col'> {/* youtube link */}
            <a
              className='epLink'
              href='https://www.youtube.com/channel/UCTmkn34eJRuo0zYXpWeSfGQ'
              target='_blank'
              rel='noreferrer'
            >
              <div className='epIconText'>Youtube</div>
              <div className='epIconText footerIcon'>
                <img className='epIcon' src={yt} alt="youtube logo"/>
              </div>
            </a>
          </div>
          <div className='col hidden'> {/* youtube link */}
            <a
              className='epLink'
              href='https://www.youtube.com/channel/UCsA1mhvf9px5zVo2EFXAsEg'
              target='_blank'
              rel='noreferrer'
            >
              <div className='epIconText'>Youtube (Shorts)</div>
              <div className='epIconText footerIcon'>
                <img className='epIcon' src={yt} alt="youtube logo" />
              </div>
            </a>
          </div>
          <div className='col'> {/* instagram link */}
            <a
              className='epLink'
              href='https://www.instagram.com/librekraft/'
              target='_blank'
              rel='noreferrer'
            >
              <div className='epIconText'>Instagram</div>
              <div className='epIconText footerIcon'>
                <img className='epIcon' src={insta} alt="instagram logo"/>
              </div>
            </a>
          </div>
          <div className='col hidden'> {/* tiktok link */}
            <a
              className='epLink'
              href='https://www.tiktok.com/@librekraft?lang=en'
              target='_blank'
              rel='noreferrer'
            >
              <div className='epIconText'>TikTok</div>
              <div className='epIconText footerIcon'>
                <img className='epIcon' src={tik} alt="tiktok logo"/>
              </div>
            </a>
          </div>
          <div className='col hidden'> {/* patreon link */}
            <a 
              className='epLink' 
              href='https://www.patreon.com/user?u=82715328' 
              target='_self'
              rel='noreferrer'
              >
              <div className='epIconText'>Patreon</div>
              <div className='epIconText footerIcon'>
                <img className='epIcon' src={pat} alt="patreon logo"/>
              </div>
            </a>
          </div>
        </div>
        <div className='row pt-5'>
          <h4 className="mb-5">Highlighted Recent Projects</h4>
        </div>

        <div className='row'>
        <div className="col-sm-6 col-md-4 col-lg-3 p-3">
            <div className="card">
              <img className="card-img-top" src={TURNTABLE} alt="lk" />
              <div className="card-body">
                <h5 className="card-title">BLE Turntable</h5>
                <p className="card-text">A BLE-enabled Photography Turntable with a desktop app</p>
                <a className="btn btn-simple" href="https://docs.google.com/document/d/19zmYutwZPplyrTtvQo8BcsjXhPR0p79rMSS3H5eJwSU/edit?usp=sharing" target="_blank" rel="noreferrer">See More</a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 p-3">
            <div className="card">
              <img className="card-img-top" src={CYCLOID} alt="lk" />
              <div className="card-body">
                <h5 className="card-title">Cycloidal Drive</h5>
                <p className="card-text">I built an OnShape FeatureScript to help me design cycloidal drives</p>
                <a className="btn btn-simple" href="https://youtu.be/1z6b_Nl9Z3Q" target="_blank" rel="noreferrer">See More</a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 p-3">
            <div className="card">
              <img className="card-img-top" src={STRING_ART} alt="lk" />
              <div className="card-body">
                <h5 className="card-title">String Art</h5>
                <p className="card-text">algorithm for generating string art</p>
                <a className="btn btn-simple" href="https://www.instagram.com/p/C23PtlWxOOb/" target="_blank" rel="noreferrer">See More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



export default LibreKraft

// website icon made by FreePik from flaticon
// youtube icon made by FreePik from flaticon
// instagram icon made by edt.im from flaticon
// tiktok icon made by FreePik from flaticon
// patreon icon made by Pixel perfect from flaticon
