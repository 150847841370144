import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import CAM from "./thumbnails/CAM.jpg"
import I2P from "./thumbnails/I2P.jpg"
import WAKE from "./thumbnails/WAKE.jpg"
import ME4405 from "./thumbnails/ME4405.jpg"
import Cap from "./thumbnails/CAP.jpg"
import LKLogo from "./thumbnails/LKLogo.png"
import SSLogo from "./thumbnails/SSLogo.png"
import DOG from "./thumbnails/DOG.jpg"

const ProjCard = ({ info }) => {
  const { title, date, desc, status, img, writeup, links, tags } = info
  const progColor = status.toLowerCase().includes("complete")
    ? "prog-complete"
    : "prog-prog"

  const handleOnClick = (e) => {
    console.log(writeup[0] === "/")
    if (writeup[0] === "/") {
      window.open(writeup, "_self")
    } else {
      window.open(writeup, "_blank")
    }
  }

  const handleTo = () => {
    console.log(writeup[0] === "/")
    if (writeup[0] === "/") {
      return writeup
    } else {
      return null
    }
  }

  const getPicture = (val) => {
    switch (val) {
      case "CAM.jpg":
        return CAM
      case "I2P.jpg":
        return I2P
      case "WAKE.jpg":
        return WAKE
      case "ME4405.jpg":
        return ME4405
      case "CAP.jpg":
        return Cap
      case "LKLogo.png":
        return LKLogo
      case "SSLogo.png":
        return SSLogo
      case "DOG.jpg":
        return DOG
      default:
        return ""
    }
  }

  return (
    <div className='projCard-cover'>
      <p className='projCard-cover-text'>SEE MORE</p>
      <Link to={handleTo()} className='proj-link'>
        <div className='proj-box' onClick={(e) => handleOnClick(e)}>
          <div className='row'>
            <div className='col-md col-sm-12'>
              <div>
                <img className='proj-box-img' src={getPicture(img)} alt="project box"/>
              </div>
            </div>
            <div className='col-md-9 col-sm-12'>
              <div className='row'>
                <div className='col-10'>
                  <h1 className='proj-box-title'>{title} </h1>
                </div>
                <div className='col-2'>
                  <span className={"proj-status " + progColor}></span>
                </div>
              </div>
              <p>{desc}</p>
              <div className='row'>
                <div className='col-4'>
                  <span>
                    <span style={{ fontWeight: "bold" }}>Date:</span> {date}
                  </span>
                </div>
                <div className='col-8'>
                  <span>
                    <span style={{ fontWeight: "bold" }}>Tags:</span> {tags}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

ProjCard.propTypes = {
  info: PropTypes.object.isRequired,
}

export default ProjCard
