import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/App.css";
import "./styles/sside.css";
import Landing from "./components/layout/Landing";
import Navbar from "./components/global/Navbar";
import Footer from "./components/global/Footer";

import About from "./components/layout/About";
import Contact from "./components/layout/Contact";
import Projects from "./components/layout/Projects";

import LibreKraft from "./components/projects/pages/LibreKraft";
import Capstone from "./components/projects/pages/Capstone";
import Sside from "./components/projects/pages/Sside";
import DogFeeder from "./components/projects/pages/DogFeeder";

function App() {
  return (
    <Router>
      <Fragment>
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/capstone" element={<Capstone />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/librekraft" element={<LibreKraft />} />
          <Route path="/sside" element={<Sside />} />
          <Route path="/dogfeeder" element={<DogFeeder />} />
        </Routes>
        <Footer />
      </Fragment>
    </Router>
  );
}

export default App;
